import * as moment from "moment";

import { AccoKind, Amenity, AmenityCategory, AvailabilityResult, Resort, StayPeriodDef } from "@maxxton/cms-mxts-api";
import { createDateMap, getStayPeriodDefs } from "../mxts.util";

import { Availability } from "./searchFacet.types";
import { DateMap } from "../mxts.types";
import { cloneDeep } from "lodash";

export function parseAvailability(
    accoKinds: AccoKind[],
    stayPeriodDefs: StayPeriodDef[],
    availability: AvailabilityResult,
    amenityCategories?: AmenityCategory[],
    resortAmenities?: Amenity[],
    resorts?: Resort[]
): Availability {
    if (!availability.response.arrivalDates || availability.response.arrivalDates.length === 0) {
        return { availableDurations: [], availableAccoKinds: [], availableStayPeriodDefs: [], modalAvailableStayPeriodDefs: [], availableAmenityCategories: [], availableResortAmenities: [] };
    }
    const availableDates: DateMap = getArrivalDates(availability);
    const availableDurations: number[] = availability.response.durations!;
    const availableStayPeriodDefs = availability.response.stayPeriodDefs && getStayPeriodDefs(stayPeriodDefs, availability);
    const availableAccoKinds: AccoKind[] = [];

    // This will ensure that the available accokinds are soted based on priority
    accoKinds.forEach((accoKind) => {
        const accommodationKind = availability.response.accommodationkinds!.indexOf(accoKind.accommodationkindId) > -1 ? accoKind : null;
        if (!accommodationKind) {
            // eslint-disable-next-line  no-console
            console.log(`Unknown accoKind id: ${accommodationKind}`);
        } else {
            availableAccoKinds.push(accommodationKind);
        }
    });

    let availableResortAmenities: Amenity[] = [];
    let availableAmenityCategories: AmenityCategory[] | undefined;

    if (amenityCategories) {
        availableAmenityCategories = cloneDeep(amenityCategories);

        availableAmenityCategories!.forEach((cat) => {
            if (cat.amenities) {
                cat.amenities = cat.amenities.filter((ame) => availability.response.amenities.some((amenity) => amenity.key === ame.amenityId));
                updateAmenityCounts(availability, cat);
                if (cat.code === "resort") {
                    availableResortAmenities = cat.amenities;
                }
            }
        });
    }

    if (resortAmenities) {
        availableResortAmenities = resortAmenities.filter((ame) => availability.response.amenities.some((amenity) => amenity.key === ame.amenityId));
    }
    let availableResorts: Resort[] = [];
    if (availableResortAmenities.length <= 0 && resorts) {
        availableResorts = resorts;
    }
    return {
        availableDates,
        modalAvailableArrivalDates: availableDates,
        availableDurations,
        availableResortAmenities,
        availableAccoKinds,
        availableStayPeriodDefs,
        modalAvailableStayPeriodDefs: availableStayPeriodDefs,
        availableAmenityCategories,
        availableResorts,
    };
}

export function getArrivalDates(availability: AvailabilityResult): DateMap {
    const availableDatesArray: moment.Moment[] = availability.response.arrivalDates!.map((date) => moment(date).utc());
    return createDateMap(availableDatesArray);
}

export function updateAmenityCounts(availability: AvailabilityResult, category: AmenityCategory) {
    if (category.amenities) {
        category.amenities.forEach((amenity: Amenity) => {
            let count;
            if (!amenity.isUnavailable) {
                const amenityFound = availability.response.amenities.find((_amenity) => _amenity.key === amenity.amenityId);
                if (amenityFound?.count) {
                    count = amenityFound.count;
                }
            }
            amenity.count = count;
        });
    }
}
