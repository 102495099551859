import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";

import { FormSpec, InputSpecMulti, InputSpecSimple, localized } from "../../../form-specs";
import { I18nLocaleObject, getI18nLocaleObject } from "../../../i18n";
import { LocaleContent, WidgetOptions } from "./TypeSearch";
import { PageWidgetSpec, Widget } from "../../";
import { accoKindOptions, getDistributionChannelLazyLoadPage, getMxtsEnv } from "../";
import {
    amenityCategoryMultiSelect,
    dynamicFieldPrice,
    getDateFormat,
    getSelectedAmenities,
    getSubjectsList,
    multiSelectSpecials,
    resortAutocomplete,
    resortMultiSelector,
} from "../../../components/utils";

import { DATE_FORMAT } from "../../../utils/constants";
import { Sort } from "../searchfacet/searchFacet.enum";
import { TypeSearchContainer } from "./TypeSearchContainer";
import { WidgetGroup } from "../../widget.enum";
import { autocompleteSiteSpec } from "../../../form-specs/models/autocompleteSite";
import namespaceList from "../../../i18n/namespaceList";
import { pageSpec } from "../../../form-specs/models/page";
import { templateSpec } from "../../../form-specs/models";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";

/**
 * This option form contains DC option which is added now just for purpose of checking the result.
 *  Later on it will removed so as the distributionChannelOptions function
 */

const descList = (): any[] => [
    {
        value: "description",
        text: getI18nLocaleObject(namespaceList.dynamicPlugin, "description"),
    },
    {
        value: "description2",
        text: getI18nLocaleObject(namespaceList.dynamicPlugin, "description2"),
    },
    {
        value: "shortDescription",
        text: getI18nLocaleObject(namespaceList.dynamicPlugin, "shortDescription"),
    },
    {
        value: "name",
        text: getI18nLocaleObject(namespaceList.dynamicPlugin, "name"),
    },
    {
        value: "dynamicFieldCode",
        text: getI18nLocaleObject(namespaceList.dynamicPlugin, "dynamicFieldCode"),
    },
];

/* jscpd:ignore-start */
const typeWidgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "typesearch-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "typesearchOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetTypeSearch, "typesearchOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "general"),
                    properties: [
                        [
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "facetTitle",
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "facetTitle"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "setStickyId",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "setStickyId"),
                                type: "text",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "options"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "descriptionUnitSearchOptions"),
                            },
                            {
                                variable: "distributionChannelId",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "distributionChannel"),
                                type: "lazyLoadAutoComplete",
                                lazyLoadOptions: (page: number, searchQuery: string, id) => getDistributionChannelLazyLoadPage(page, searchQuery, id ? [id] : undefined, MXTS.MxtsApi),
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
                            },
                            {
                                variable: "minArrivalDate",
                                type: "date",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "minArrivalDate"),
                            },
                            {
                                variable: "maxArrivalDate",
                                type: "date",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "maxArrivalDate"),
                            },
                            {
                                variable: "useDynamicFieldToShowLocation",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "useDynamicFieldToShowLocation"),
                                type: "checkbox",
                            },
                            dynamicFieldPrice("locationDynamicField", undefined, "useDynamicFieldToShowLocation"),
                            {
                                variable: "useResortForSubjects",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "useResortForSubjects"),
                                type: "checkbox",
                            },
                            resortAutocomplete("resortId", undefined, true),
                            {
                                variable: "useTypeLink",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "useTypeLink"),
                                type: "checkbox",
                            },
                            {
                                variable: "useOtherMediaAssets",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "useOtherMediaAssets"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "defaultSortOption",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "defaultSortOption"),
                                type: "select",
                                optionList: [
                                    {
                                        value: Sort.defaultSorting,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "defaultSorting"),
                                    },
                                    {
                                        value: Sort.lowToHighPrice,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "lowToHighPrice"),
                                    },
                                    {
                                        value: Sort.highToLowPrice,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "highToLowPrice"),
                                    },
                                    {
                                        value: Sort.ascendingName,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "ascendingName"),
                                    },
                                    {
                                        value: Sort.descendingName,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "descendingName"),
                                    },
                                    {
                                        value: Sort.highToLowRating,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "highToLowRating"),
                                    },
                                    {
                                        value: Sort.lowToHighRating,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "lowToHighRating"),
                                    },
                                    {
                                        value: Sort.priority,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "sortOnPriority"),
                                    },
                                ],
                            },
                            {
                                variable: "selectDefaultSubject",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "selectDefaultSubject"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "defaultSubject",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "defaultSubject"),
                                type: "autocomplete",
                                isClearable: false,
                                options: () => getSubjectsList(MXTS.MxtsApi),
                                visible: (options: WidgetOptions) => !!options.selectDefaultSubject,
                            },
                            {
                                variable: "nrOfDefaultSubjects",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "nrOfDefaultSubjects"),
                                type: "number",
                                visible: (options: WidgetOptions) => !!options.selectDefaultSubject,
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "objectPageSiteId"),
                                        variable: "baseUrlSiteId",
                                        type: "autocomplete",
                                        refType: autocompleteSiteSpec,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "objectPagePageId"),
                                        variable: "baseUrlPageId",
                                        type: "autocomplete",
                                        refType: pageSpec,
                                        dependsOnSiteSpec: "baseUrlSiteId",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "buttonText"),
                                        variable: "buttonText",
                                        type: "text",
                                    },
                                ],
                                visible: (item: WidgetOptions) => !item.useTypeLink,
                            }),
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "typePageUrl"),
                                        variable: "typePageUrl",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "buttonText"),
                                        variable: "buttonText",
                                        type: "text",
                                    },
                                ],
                                visible: (item: WidgetOptions) => item.useTypeLink,
                            }),
                            {
                                variable: "baseBookingsUrl",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "baseBookUrl"),
                                type: "text",
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "linkText"),
                                        variable: "linkText",
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "disableSpecialParam",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "disableSpecialParam"),
                                type: "checkbox",
                            },
                            {
                                variable: "showQuote",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showQuote"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "quoteLinkUrl"),
                                        variable: "quoteLinkUrl",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "quoteButtonText"),
                                        variable: "quoteButtonText",
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.showQuote,
                            }),
                            multiSelectAccoKinds("accoKindIds", getI18nLocaleObject(namespaceList.widgetTypeSearch, "accoKinds")),
                            resortMultiSelector("resorts", false, getI18nLocaleObject(namespaceList.widgetTypeSearch, "resorts"), { setVisibility: true }),
                            amenityCategoryMultiSelect("selectedAmenityCategories"),
                            multiSelectSpecials("specialCodes", getI18nLocaleObject(namespaceList.widgetTypeSearch, "specialCodes")),
                            {
                                variable: "amenityCodes",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "amenityCodes"),
                                type: "text",
                            },
                            {
                                variable: "resortCode",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "resortCode"),
                                type: "text",
                            },
                            {
                                variable: "holidayCode",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "holidayCode"),
                                type: "text",
                            },
                            {
                                variable: "minCapacity",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "minCapacity"),
                                type: "text",
                            },
                            {
                                variable: "showSpecialCodeOnMoreFilter",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showSpecialCodeOnMoreFilter"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "display"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "descriptionUnitSearchDisplay"),
                            },
                            {
                                variable: "defaultNumberOfTypes",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "defaultNumberOfTypes"),
                                type: "text",
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForDefaultNumberOfResults"),
                            },
                            {
                                variable: "nextNumberOfTypes",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "nextNumberOfTypes"),
                                type: "text",
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForDefaultNumberOfResults"),
                            },
                            {
                                variable: "accoTypeDynamicFieldCode",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "accoTypeDynamicFieldCode"),
                                type: "text",
                            },
                            dynamicFieldPrice("dynamicFieldPriceList"),
                            {
                                variable: "hideAllAccoKindsFilter",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "hideAllAccoKinds"),
                                type: "checkbox",
                            },
                            {
                                variable: "showReferencePrice",
                                label: getI18nLocaleObject(namespaceList.admin, "showReferencePrice"),
                                type: "checkbox",
                            },
                            {
                                variable: "showManualSelection",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showDescManualSelection"),
                                type: "checkbox",
                            },
                            multiselectDescription("multiselectDescription"),
                            dynamicFieldDescription("showDynamicField"),
                            fallbackDescription("fallbackDescription"),
                            multiselectFallback("multiselectFallback"),
                            dynamicFieldFallback("dynamicFieldFallback"),
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "showAccoKindCriteriaText",
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showAccoKindCriteriaText"),
                                        type: "checkbox",
                                    },
                                    {
                                        variable: "accoKindCriteriaText",
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "accoKindCriteriaText"),
                                        type: "text",
                                        visible: (item: any) => item.showAccoKindCriteriaText,
                                    },
                                    {
                                        variable: "useAccoKindFriendlyUrl",
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "useAccoKindFriendlyUrl"),
                                        type: "checkbox",
                                        visible: (item: LocaleContent) => item.showAccoKindCriteriaText,
                                    },
                                    {
                                        variable: "allAccoKindUrl",
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "allAccoKindUrl"),
                                        type: "text",
                                        visible: (item: LocaleContent) => item.showAccoKindCriteriaText && item.useAccoKindFriendlyUrl,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "allAccoKindSite"),
                                        variable: "allAccoKindSiteId",
                                        type: "autocomplete",
                                        refType: autocompleteSiteSpec,
                                        visible: (item: LocaleContent) => item.showAccoKindCriteriaText && !item.useAccoKindFriendlyUrl,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "allAccoKindPage"),
                                        variable: "allAccoKindPageId",
                                        type: "autocomplete",
                                        refType: pageSpec,
                                        dependsOnSiteSpec: "allAccoKindSiteId",
                                        visible: (item: LocaleContent) => item.showAccoKindCriteriaText && !item.useAccoKindFriendlyUrl,
                                    },
                                    {
                                        variable: "showResortCriteriaText",
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showResortCriteriaText"),
                                        type: "checkbox",
                                    },
                                    {
                                        variable: "resortCriteriaText",
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "resortCriteriaText"),
                                        type: "text",
                                        visible: (item: LocaleContent) => item.showResortCriteriaText,
                                    },
                                    {
                                        variable: "useResortFriendlyUrl",
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "useResortFriendlyUrl"),
                                        type: "checkbox",
                                        visible: (item: LocaleContent) => item.showResortCriteriaText,
                                    },
                                    {
                                        variable: "allResortUrl",
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "allResortUrl"),
                                        type: "text",
                                        visible: (item: LocaleContent) => item.showResortCriteriaText && item.useResortFriendlyUrl,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "allResortSite"),
                                        variable: "allResortSiteId",
                                        type: "autocomplete",
                                        refType: autocompleteSiteSpec,
                                        visible: (item: LocaleContent) => item.showResortCriteriaText && !item.useResortFriendlyUrl,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "allResortPage"),
                                        variable: "allResortPageId",
                                        type: "autocomplete",
                                        refType: pageSpec,
                                        dependsOnSiteSpec: "allResortSiteId",
                                        visible: (item: LocaleContent) => item.showResortCriteriaText && !item.useResortFriendlyUrl,
                                    },
                                ],
                            }),
                            {
                                variable: "dateRangePicker",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dateRangePicker"),
                                type: "checkbox",
                            },
                            ...getDateFormat("dateFormat"),
                            {
                                variable: "showStartEndDateLabel",
                                label: getI18nLocaleObject(namespaceList.admin, "showStartEndDateLabel"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.dateRangePicker,
                            },
                            {
                                variable: "multiAccoKindSelector",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "multiAccoKindSelector"),
                                type: "checkbox",
                            },
                            {
                                variable: "multiResortSelector",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "multiResortSelector"),
                                type: "checkbox",
                            },
                            {
                                variable: "searchButton",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "searchButton"),
                                type: "checkbox",
                            },
                            {
                                variable: "showDateStayPopup",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "showDateStayPopup"),
                                type: "checkbox",
                            },
                            {
                                variable: "showSpecialDatesByDefault",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showSpecialDatesByDefault"),
                                type: "checkbox",
                            },
                            {
                                variable: "showDirectSearchFilter",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "showDirectSearchFilter"),
                                type: "checkbox",
                            },
                            {
                                variable: "hideUnitDirectSearch",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "searchUnitsDirectly"),
                                type: "checkbox",
                            },
                            {
                                variable: "showMinStayDuration",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showMinStayDuration"),
                                type: "checkbox",
                            },
                            {
                                variable: "showUspAmenities",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showUspAmenities"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "fetchAmenitiesCount",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showUspAmenitiesCount"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.showUspAmenities,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "extra"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "extraInfoDescription"),
                            },
                            {
                                variable: "webContentId",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundWebcontent"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
                            },
                            {
                                variable: "templateId",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplate"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "unitBookUri",
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "unitBookUrl"),
                                        type: "text",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
            ],
        },
    ],
};
/* jscpd:ignore-end */

export function multiSelectAccoKinds<E, P extends keyof E>(variable: P, label: I18nLocaleObject | string, setVisibility?: boolean): InputSpecMulti<E, P> {
    return {
        variable,
        label,
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return accoKindOptions(MXTS.MxtsApi);
        },
        placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "accoKindsPlaceholder"),
        visible: setVisibility ? (options: any) => options.preFilteredAvailability : undefined,
    };
}

function multiselectDescription<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "descriptionTypeMultiSelector"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return descList();
        },
        visible: (options: any) => options.showManualSelection && !options.dynamicFieldCode,
    };
}

function dynamicFieldDescription<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecSimple<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "dynamicFieldCode"),
        type: "text",
        visible: (options: any) =>
            (options.multiselectDescription !== undefined && options.multiselectDescription.find((check: any) => check.value === "dynamicFieldCode") ? true : false) === true &&
            options.showManualSelection,
    };
}

function multiselectFallback<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "descriptionFallbackTypeMultiSelector"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return descList();
        },
        visible: (options: any) => options.fallbackDescription && !options.fallbackdynamicField && options.showManualSelection,
    };
}

function fallbackDescription<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecSimple<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.widgetTypeSearch, "fallbackDescription"),
        type: "checkbox",
        visible: (options: any) => options.showManualSelection,
    };
}

function dynamicFieldFallback<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecSimple<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "dynamicFieldCode"),
        type: "text",
        visible: (options: any) =>
            options.fallbackDescription &&
            (options.multiselectFallback !== undefined && options.multiselectFallback.find((check: any) => check.value === "dynamicFieldCode") ? true : false) === true &&
            options.showManualSelection,
    };
}

/* jscpd:ignore-start */
export const typeSearchWidget: PageWidgetSpec<WidgetOptions> = {
    id: "typesearch",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "typesearch"),
    description: getI18nLocaleObject(namespaceList.widgetTypeSearch, "typesearchDescription"),
    optionsForm: typeWidgetOptionsForm,
    defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
        localizedContent: [],
        distributionChannelId: null,
        dateRangePicker: false,
        setStickyId: "",
        multiAccoKindSelector: true,
        multiResortSelector: false,
        webContentId: null,
        searchButton: false,
        showDateStayPopup: true,
        multiselectDescription: [],
        dynamicFieldCode: false,
        showDynamicField: "",
        fallbackdynamicField: false,
        dynamicFieldFallback: "",
        fallbackDescription: false,
        showSpecialCodeOnMoreFilter: false,
        showDirectSearchFilter: false,
        hideUnitDirectSearch: false,
        useTypeLink: false,
        showQuote: false,
        showMinStayDuration: false,
        showUspAmenities: false,
        dynamicFieldPriceList: [],
        selectedAmenityCategories: [],
        useOtherMediaAssets: false,
        fetchAmenitiesCount: false,
        showStartEndDateLabel: false,
        dateFormat: DATE_FORMAT.DISPLAY,
    }),
    /* jscpd:ignore-end */

    async render(widget: Widget<WidgetOptions>, context) {
        // If a category is specified, then dont get all amenities by placing a request
        const amenityCategories: MXTS.AmenityCategory[] =
            widget.options.selectedAmenityCategories && widget.options.selectedAmenityCategories.length > 0
                ? await getSelectedAmenities(context.mxtsApi, widget.options.selectedAmenityCategories, undefined, await getMxtsEnv(context, context.currentLocale.code))
                : [];

        return <TypeSearchContainer options={widget.options} context={context} selectedAmenityCategories={amenityCategories} />;
    },
};
