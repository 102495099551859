import { ApiCallOptions, MxtsApiWrapper, PagedResult, StayPeriodDef } from "@maxxton/cms-mxts-api";

import { DomainObjectUtil } from "./domainobject.util";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { MXTS } from "./constants";
import { StringUtil } from "./string.util";
import { chunk } from "lodash";
import { getHolidayByCode } from "../plugins/mxts";

const { PAGE_REQUEST_SIZE } = MXTS;

export const fetchStayPeriodDefsByIds = async ({ stayPeriodDefIds, env }: { stayPeriodDefIds: number[]; env: ApiCallOptions }, mxtsApi: MxtsApiWrapper): Promise<StayPeriodDef[]> => {
    if (!stayPeriodDefIds.length) {
        return [];
    }
    const stayPeriodDefIdChunks = chunk(stayPeriodDefIds, PAGE_REQUEST_SIZE);
    const stayPeriodDefPromises = stayPeriodDefIdChunks.map((stayPeriodDefIds) =>
        mxtsApi.stayPeriodDefs(env, { size: PAGE_REQUEST_SIZE, stayPeriodDefIds }).then((stayPeriodDefsResponse: PagedResult<StayPeriodDef>) => stayPeriodDefsResponse.content)
    );
    const stayPeriodDefs = (await Promise.all(stayPeriodDefPromises)).flat();
    return stayPeriodDefs;
};

export async function getStayPeriodDefFilters(
    mxtsApi: MxtsApiWrapper,
    { defaultStay, env, dynamicFilter, holidayCode }: { defaultStay?: number; env: ApiCallOptions; dynamicFilter: DynamicFilter; holidayCode?: string }
) {
    const stayPeriodFilters: Pick<DynamicFilter, "stayperioddefid" | "stayHolidayPeriodDefId" | "holiday"> = {};
    const stayPeriodDefData = await getHolidayByCode(mxtsApi, env, dynamicFilter.holiday && !holidayCode ? dynamicFilter.holiday : holidayCode);
    if (defaultStay) {
        const defaultStayPeriodDef: StayPeriodDef | null = await DomainObjectUtil.getStayPeriodDef(mxtsApi, defaultStay, env);
        if (!dynamicFilter.stayHolidayPeriodDefId && StringUtil.equalsIgnoreCase(defaultStayPeriodDef?.type || "", "holiday")) {
            stayPeriodFilters.stayHolidayPeriodDefId = defaultStay;
        } else if (!dynamicFilter.stayperioddefid) {
            stayPeriodFilters.stayperioddefid = defaultStay;
        }
    }
    if ((holidayCode && !dynamicFilter.stayHolidayPeriodDefId) || (dynamicFilter.holiday && !holidayCode) || dynamicFilter.holiday) {
        stayPeriodFilters.stayHolidayPeriodDefId = stayPeriodDefData?.stayPeriodDefId;
        stayPeriodFilters.holiday = dynamicFilter.holiday && !holidayCode ? dynamicFilter.holiday : holidayCode;
    }
    return stayPeriodFilters;
}
