import * as MXTS from "@maxxton/cms-mxts-api";
import * as moment from "moment";

import { ALL_AMENITIES_AGGREGATION, RESOURCES_AGGREGATION, STAY_PERIOD_DEF_AGGREGATION, getAvailability, getBaseFilter } from "../../../utils/availability.util";
import { AccommodationType, getAmenityByCode, getHolidayByCode, getMxtsEnv, getResortAmenities } from "../../mxts";
import { Availability, SearchFacetState } from "../searchfacet/searchFacet.types";
import { AvailabilityRequest, LOCAL_STORAGE_KEY, getAmenityIdsFromCodes, parseAvailability } from "../";
import { getElasticSubjectFilter, getPetCapacity } from "../../../utils/searchFacet.utils";

import { ApiContext } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { Sort } from "../searchfacet/searchFacet.enum";
import { WidgetOptions } from "./TypeSearch";
import { fetchStayPeriodDefsByIds } from "../../../utils/stayPeriodDefs.util";
import { getSelectedAmenities } from "../../../components/utils";
import { parseAvailability as parseSearchFacetAvailability } from "../searchfacet/searchFacet.util";

export interface AvailabilityModel {
    availabilityRequest: AvailabilityRequest;
    baseFilter: MXTS.AvailabilityRequest;
    typeSearchResult: AccommodationType[];
    SearchFacetAvailability: Availability;
    ops: MXTS.ApiCallOptions;
    availability: MXTS.AvailabilityResult;
}

interface Filter {
    baseFilter: MXTS.AvailabilityRequest;
    selectedSubjects: Map<number, number>;
    selectedAmenityIds?: number[];
    holiday?: MXTS.StayPeriodDef;
}

// eslint-disable-next-line max-lines-per-function
async function getFilter(
    apiContext: ApiContext,
    widgetOptions: WidgetOptions,
    options: any,
    ops: MXTS.ApiCallOptions,
    mxtsResorts: MXTS.Resort[],
    subjects: MXTS.Subject[],
    resortAmenities: MXTS.Amenity[] | undefined,
    searchFacetState?: SearchFacetState,
    selected?: boolean
): Promise<Filter> {
    const { accoKindIds, distributionChannelId, resortCode, specialCodes, amenityCodes, holidayCode, minCapacity, minArrivalDate, maxArrivalDate, resorts } = widgetOptions;

    const env = await getMxtsEnv(apiContext);
    const amenitieCategories =
        widgetOptions.selectedAmenityCategories && widgetOptions.selectedAmenityCategories.length > 0
            ? await getSelectedAmenities(apiContext.mxtsApi, widgetOptions.selectedAmenityCategories, undefined, env)
            : [];

    const amenities: number[] = [];
    amenitieCategories.forEach((amenityCategory) => amenities.push(...amenityCategory.amenities!.map((a) => a.amenityId)));

    const resortcode = resortCode || options.resort;
    const amenityByCode = getAmenityByCode(resortAmenities, resortcode);
    const resortAmenityId = searchFacetState
        ? searchFacetState.resortAmenity
            ? searchFacetState.resortAmenity.amenityId
            : undefined
        : resortcode && amenityByCode
        ? amenityByCode.amenityId
        : undefined;
    const resortId = resortAmenityId ? undefined : resortCode;
    const selectedAccoKinds = searchFacetState
        ? searchFacetState.selectedAccoKinds
            ? searchFacetState.selectedAccoKinds.map((accoKind) => accoKind.accommodationkindId)
            : undefined
        : accoKindIds && accoKindIds.length > 0
        ? accoKindIds.map((accoKind) => accoKind.value)
        : undefined;

    const selectedResorts = searchFacetState
        ? searchFacetState.selectedResorts
            ? searchFacetState.selectedResorts.map((resort) => resort.resortId)
            : undefined
        : resorts && resorts.length > 0
        ? resorts.map((resort) => resort.value)
        : undefined;

    options.startdate = searchFacetState ? (searchFacetState.startDate ? searchFacetState.startDate.format(DATE_FORMAT.DEFAULT) : undefined) : options.startdate;
    options.enddate = searchFacetState ? (searchFacetState.endDate ? searchFacetState.endDate.format(DATE_FORMAT.DEFAULT) : undefined) : options.enddate;

    options.stayperioddefid = searchFacetState
        ? searchFacetState.stayPeriodDefId
            ? searchFacetState.stayPeriodDefId
            : undefined
        : options.stay
        ? (await apiContext.mxtsApi.stayPeriodDefs(ops, { code: options.stay }).then((stay) => stay.content))[0].stayPeriodDefId
        : options.stayperioddefid;

    options.holiday = searchFacetState ? searchFacetState.holiday : holidayCode || options.holiday;
    options.minArrivalDate = options.minArrivalDate || (minArrivalDate ? moment(minArrivalDate).format(DATE_FORMAT.DEFAULT) : undefined);
    options.maxArrivalDate = options.maxArrivalDate || (maxArrivalDate ? moment(maxArrivalDate).format(DATE_FORMAT.DEFAULT) : undefined);
    let holiday: MXTS.StayPeriodDef | undefined;

    if (searchFacetState) {
        holiday = searchFacetState.holiday;
    } else if (options.holiday) {
        holiday = await getHolidayByCode(apiContext.mxtsApi, ops, options.holiday);
    }

    options.stayHolidayPeriodDefId = holiday ? holiday.stayPeriodDefId : undefined;

    options.minCapacity = searchFacetState ? searchFacetState.minCapacity : minCapacity || options.minCapacity;

    let amenityIds: number[] = [];
    if (searchFacetState) {
        amenityIds = searchFacetState.amenityIds;
        options.amenities = amenityIds;
        if (amenities && amenities.length > 0 && !selected) {
            options.amenities = Array.from(new Set([...options.amenities, ...amenities]));
        }
    } else if (options.amenity) {
        amenityIds = await getAmenityIdsFromCodes(apiContext.mxtsApi, options.amenity, ops);
        options.amenities = amenityIds;
        if (amenities && amenities.length > 0 && !selected) {
            options.amenities = Array.from(new Set([...options.amenities, ...amenities]));
        }
    } else if (amenityCodes && amenityCodes.split(",").length > 0) {
        amenityIds = await getAmenityIdsFromCodes(apiContext.mxtsApi, amenityCodes, ops);
        options.amenities = amenityIds;
        if (amenities && amenities.length > 0 && !selected) {
            options.amenities = Array.from(new Set([...options.amenities, ...amenities]));
        }
    }
    if (resortAmenityId) {
        if (options.amenities) {
            if (options.amenities.indexOf(resortAmenityId) === -1) {
                options.amenities.push(resortAmenityId);
            }
            if (amenities && amenities.length > 0 && !selected) {
                options.amenities = Array.from(new Set([...options.amenities, ...amenities]));
            }
        } else {
            options.amenities = [resortAmenityId];
        }
    }
    if (resortId && !options.resort && resortCode && !isNaN(+resortCode)) {
        options.resortid = resortId;
    }

    let widgetOptionsSpecialCodes: string[] | undefined;
    if (specialCodes && typeof specialCodes === "string") {
        widgetOptionsSpecialCodes = specialCodes.split(",").map((data) => data.trim());
    } else if (specialCodes?.length) {
        widgetOptionsSpecialCodes = (specialCodes as any)?.map((specialCode: any) => specialCode?.value || specialCode) || specialCodes;
    }
    options.specialcode = searchFacetState
        ? searchFacetState.specialCodes
        : widgetOptionsSpecialCodes || (options.specialcode ? (Array.isArray(options.specialcode) ? options.specialcode.join() : options.specialcode) : undefined);

    let elasticSubjects: MXTS.ElasticSubject[] = [];
    const selectedSubjects: Map<number, number> = new Map();
    let petCapacity: number | undefined;
    const { selectDefaultSubject, defaultSubject, nrOfDefaultSubjects } = widgetOptions;
    if (selectDefaultSubject && defaultSubject && nrOfDefaultSubjects) {
        selectedSubjects.set(defaultSubject, +nrOfDefaultSubjects);
    }
    if (searchFacetState) {
        if (searchFacetState.selectedSubjects) {
            searchFacetState.selectedSubjects.forEach((value, key) => {
                selectedSubjects.set(key, +value);
            });
            elasticSubjects = getElasticSubjectFilter(subjects, searchFacetState.selectedSubjects);
            petCapacity = getPetCapacity(subjects, searchFacetState.selectedSubjects);
        }
    } else if (options.subject && (options.subject.indexOf(",") !== -1 || options.subject.length > 0)) {
        if (Array.isArray(options.subject)) {
            options.subject.forEach((sub: string) => {
                const commaIndex = sub.indexOf(",");
                const subjectId = sub.slice(0, commaIndex);
                const SubjectCount = sub.slice(commaIndex + 1);
                selectedSubjects.set(+subjectId, +SubjectCount);
            });
        } else {
            const commaIndex = options.subject.indexOf(",");
            const subjectId = options.subject.slice(0, commaIndex);
            const SubjectCount = options.subject.slice(commaIndex + 1);
            selectedSubjects.set(+subjectId, +SubjectCount);
        }
        if (selectedSubjects.size > 0) {
            elasticSubjects = getElasticSubjectFilter(subjects, selectedSubjects);
            petCapacity = getPetCapacity(subjects, selectedSubjects);
        }
    }

    let customAggregations: MXTS.Aggregation[] | undefined;
    if (env.env.concern !== "krm" && env.env.concern !== "eps") {
        customAggregations = [
            {
                name: "RESORT_FACET",
                field: "RESORT_ID",
                excludeFields: ["RESORT_ID"],
                type: "FACET",
                size: 1000,
            },
            {
                name: "ACCOMMODATIONKIND_FACET",
                field: "ACCOMMODATIONKIND_ID",
                excludeFields: ["ACCOMMODATIONKIND_ID"],
                type: "FACET",
                size: 1000,
            },
            {
                name: "ARRIVAL_DATE_FACET",
                field: "ARRIVAL_DATE",
                type: "FACET",
                excludeFields: ["ARRIVAL_DATE", "DURATION"],
                size: 1000,
            },
            {
                name: "ALL_AMENITIES_FACET",
                field: "ALL_AMENITIES",
                type: "FACET",
                size: 1000,
            },
        ];
    }
    return {
        baseFilter: getBaseFilter({
            options,
            distributionChannelId: distributionChannelId!,
            customAggregations,
            resortId: searchFacetState?.resortId,
            accoKindIds: selectedAccoKinds,
            elasticSubjects,
            petCapacity,
            fetchUnitsWithPrice: "false",
            fetchUnitFacet: false,
            fetchBedBathFacet: false,
            resortIds: selectedResorts,
        }),
        selectedAmenityIds: amenityIds,
        selectedSubjects,
        holiday,
    };
}

// eslint-disable-next-line max-lines-per-function
export async function getTypeSearchData(
    apiContext: ApiContext,
    widgetOptions: WidgetOptions,
    options: any,
    fromIndex: number,
    toIndex: number,
    urlParams: any,
    locale?: string,
    searchFacetState?: SearchFacetState,
    useResortForSubjects?: boolean,
    resortId?: boolean,
    selected?: boolean,
    dateRangePicker?: boolean
): Promise<AvailabilityModel> {
    const { distributionChannelId, accoTypeDynamicFieldCode, selectedAmenityCategories, defaultSortOption, _id: widgetOptionsId } = widgetOptions;
    const widgetOptionsDynamicFieldCodesPaths: Array<keyof WidgetOptions> = ["locationDynamicField", "dynamicFieldPriceList", "dynamicFieldFallback", "accoTypeDynamicFieldCode"];
    if (distributionChannelId == null) {
        throw new Error("No distribution channel given");
    }
    const ops = await getMxtsEnv(apiContext, locale);

    const [resortsAndAddresses, subjects, amenityCategories] = await Promise.all([
        MXTS.getAllWithAddress((size: number, page: number) => apiContext.mxtsApi.resortsWithAddress(ops, { size, page })),
        apiContext.mxtsApi
            .subjects(ops, { types: ["PERSON", "PET"], resortId: useResortForSubjects && resortId ? +resortId : undefined, endDate: moment().format("YYYY-MM-DD"), sort: "subjectOrder" })
            .then((res) => res.content),
        apiContext.mxtsApi.amenityCategories(ops, { size: 2000, internetSearchGroup: true, isInternalUse: false, view: "detail", sort: "priority" }).then((cat) => cat.content),
    ]);

    const resorts: MXTS.Resort[] = resortsAndAddresses?.data?.content || [];

    let resortAmenities: MXTS.Amenity[] | undefined;
    const resortAmen = amenityCategories.find((cat: MXTS.AmenityCategory) => cat.code === "resort");
    if (resortAmen) {
        resortAmenities = getResortAmenities(amenityCategories);
    }

    const filter = await getFilter(apiContext, widgetOptions, options, ops, resorts, subjects, resortAmenities, searchFacetState, selected);
    let { baseFilter, selectedSubjects, selectedAmenityIds } = filter;

    if (widgetOptions.fetchAmenitiesCount) {
        const amenitiesAggrIndex = baseFilter.aggregations.findIndex((aggregation) => ALL_AMENITIES_AGGREGATION.name === aggregation.name);
        const amenitiesAggregation = { ...baseFilter.aggregations[amenitiesAggrIndex] };
        const isResource = baseFilter.aggregations.some((aggregation) => RESOURCES_AGGREGATION.name === aggregation.name);
        if (isResource) {
            amenitiesAggregation.count = "RESOURCE_ID";
            baseFilter.aggregations[amenitiesAggrIndex] = amenitiesAggregation;
        }
    }

    if (!dateRangePicker && baseFilter.aggregations.findIndex((aggregation) => aggregation.name === "STAY_PERIOD_DEF_FACET") === -1) {
        baseFilter.aggregations.push(STAY_PERIOD_DEF_AGGREGATION);
    }

    // Send parallel requests to availabilty for only selected Resorts
    let availabilityPerResort: any[] = [];
    if (selectedAmenityCategories && selectedAmenityCategories.length > 0 && !selected && !searchFacetState?.resortAmenity) {
        let amenities: number[] = [];
        if (!(baseFilter.amenities && baseFilter.amenities.length > 0)) {
            const resortAmenityCategories = await getSelectedAmenities(apiContext.mxtsApi, selectedAmenityCategories, undefined, ops);
            resortAmenityCategories.forEach((amenityCategory) => amenities.push(...amenityCategory.amenities!.map((a) => a.amenityId)));
        } else {
            amenities = [...baseFilter.amenities];
        }

        const amenityPromises = amenities.map((amenityID) => {
            baseFilter.amenities = [amenityID];
            return getAvailability(apiContext, ops, baseFilter, amenityID);
        });
        availabilityPerResort = await Promise.all(amenityPromises);
        baseFilter.amenities = [];
    } else {
        if (searchFacetState?.resortAmenity) {
            baseFilter.amenities = [searchFacetState?.resortAmenity.amenityId];
        }
        availabilityPerResort = [await getAvailability(apiContext, ops, baseFilter)];
    }
    let availabilityFilter = availabilityPerResort[0].filter;
    let response: any =
        selectedAmenityCategories && selectedAmenityCategories.length > 0
            ? {
                  arrivalDates: [],
                  accommodationkinds: [],
                  durations: [],
                  resorts: [],
                  resources: [],
                  stayPeriodDefs: [],
                  amenities: [],
              }
            : availabilityPerResort[0].response;
    availabilityPerResort.forEach((availability) => {
        response.arrivalDates = Array.from(new Set([...(response.arrivalDates || []), ...(availability.response.arrivalDates || [])]));
        response.accommodationkinds = Array.from(new Set([...(response.accommodationkinds || []), ...(availability.response.accommodationkinds || [])]));
        response.durations = Array.from(new Set([...(response.durations || []), ...(availability.response.durations || [])]));
        response.resorts = Array.from(new Set([...(response.resorts || []), ...(availability.response.resorts || [])]));
        response.resources = Array.from(
            new Set([...(response.resources || []).map((res: any) => JSON.stringify(res)), ...(availability.response.resources || []).map((res: any) => JSON.stringify(res))])
        ).map((res) => JSON.parse(res));
        response.stayPeriodDefs = Array.from(new Set([...(response.stayPeriodDefs || []), ...(availability.response.stayPeriodDefs || [])]));
        response.amenities = Array.from(new Set([...(response.amenities || []), ...(availability.response.amenities || [])]));
    });

    // If no response found then flush localStorage and again make elastic request.
    if (response.resources!.length === 0) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        const newfilter = await getFilter(apiContext, widgetOptions, urlParams, ops, resorts, subjects, resortAmenities, searchFacetState);
        baseFilter = newfilter.baseFilter;
        selectedSubjects = newfilter.selectedSubjects;
        selectedAmenityIds = newfilter.selectedAmenityIds;
        availabilityPerResort = [await getAvailability(apiContext, ops, baseFilter)];
        response = availabilityPerResort[0].response;
        availabilityFilter = availabilityPerResort[0].filter;
    }

    const availableResorts: MXTS.Resort[] = [];
    resorts.forEach((resort) => {
        if (response.resorts) {
            response.resorts.forEach((availableResort: any) => {
                if (resort.resortId === availableResort) {
                    availableResorts.push(resort);
                }
            });
        }
    });

    const [accoKinds] = await Promise.all([apiContext.mxtsApi.accommodationkinds(ops, { sort: "priority", size: 999 })]);
    const addresses = (resortsAndAddresses.addresses || []).filter((address: MXTS.Address) => availableResorts.some((availableResort) => availableResort.visitAddressManagerId === address.managerId));

    const stayDefIds: number[] = response.stayPeriodDefs || [];
    const resourceIds: number[] = [];
    const sortingOption = searchFacetState ? searchFacetState.sortingOption : defaultSortOption !== undefined ? Sort[+defaultSortOption] : Sort[Sort.highToLowRating];

    let resources: MXTS.Resource[] | undefined;
    let stayPeriodDefs: MXTS.StayPeriodDef[] | undefined;

    if (sortingOption === Sort[Sort.ascendingName] || sortingOption === Sort[Sort.descendingName] || sortingOption === Sort[Sort.priority]) {
        response.resources!.forEach((doc: MXTS.Document) => {
            if (doc.specialId) {
                resourceIds.push(doc.specialId);
            }
            resourceIds.push(doc.resourceId);
        });
        [resources, stayPeriodDefs] = await Promise.all([
            apiContext.mxtsApi.resources(ops, { size: 1000, resourceIds: Array.from(new Set(resourceIds)) }).then((res) => res.content),
            apiContext.mxtsApi.stayPeriodDefs(ops, { size: 40, stayPeriodDefIds: stayDefIds }).then((stay) => stay.content),
        ]);
    }

    const sortedDocs = response.resources!.sort((r1: any, r2: any) => {
        if (sortingOption === Sort[Sort.highToLowPrice]) {
            if (r1.priceInclusive > r2.priceInclusive) {
                return -1;
            }
            if (r1.priceInclusive < r2.priceInclusive) {
                return 1;
            }
            return 0;
        } else if (sortingOption === Sort[Sort.lowToHighPrice]) {
            if (r1.priceInclusive > r2.priceInclusive) {
                return 1;
            }
            if (r1.priceInclusive < r2.priceInclusive) {
                return -1;
            }
            return 0;
        } else if (sortingOption === Sort[Sort.lowToHighRating]) {
            if (r1.resourceRating > r2.resourceRating) {
                return 1;
            }
            if (r1.resourceRating < r2.resourceRating) {
                return -1;
            }
            return 0;
        } else if (sortingOption === Sort[Sort.highToLowRating]) {
            if (r1.resourceRating > r2.resourceRating) {
                return -1;
            }
            if (r1.resourceRating < r2.resourceRating) {
                return 1;
            }
            return 0;
        } else if (sortingOption === Sort[Sort.ascendingName]) {
            let resource1: MXTS.Resource | undefined;
            let resource2: MXTS.Resource | undefined;
            resources!.forEach((resource) => {
                if (r1.resourceId === resource.resourceId) {
                    resource1 = resource;
                }
                if (r2.resourceId === resource.resourceId) {
                    resource2 = resource;
                }
            });
            if (resource1!.name > resource2!.name) {
                return 1;
            }
            if (resource1!.name < resource2!.name) {
                return -1;
            }
            return 0;
        } else if (sortingOption === Sort[Sort.descendingName]) {
            let resource1: MXTS.Resource | undefined;
            let resource2: MXTS.Resource | undefined;
            resources!.forEach((resource) => {
                if (r1.resourceId === resource.resourceId) {
                    resource1 = resource;
                }
                if (r2.resourceId === resource.resourceId) {
                    resource2 = resource;
                }
            });
            if (resource1!.name > resource2!.name) {
                return -1;
            }
            if (resource1!.name < resource2!.name) {
                return 1;
            }
            return 0;
        } else if (sortingOption === Sort[Sort.priority]) {
            let resource1: MXTS.Resource | undefined;
            let resource2: MXTS.Resource | undefined;
            resources!.forEach((resource) => {
                if (r1.resourceId === resource.resourceId) {
                    resource1 = resource;
                }
                if (r2.resourceId === resource.resourceId) {
                    resource2 = resource;
                }
            });
            if ((resource1! as any).priority > (resource2! as any).priority) {
                return 1;
            }
            if ((resource1! as any).priority < (resource2! as any).priority) {
                return -1;
            }
            return 0;
        }
        return 0;
    });
    let sortedResources: MXTS.Resource[] = [];
    sortedDocs.slice(fromIndex, toIndex).map((doc: any) => {
        if (resources) {
            resources.forEach((res: MXTS.Resource) => {
                if (doc.resourceId === res.resourceId) {
                    sortedResources.push(res);
                }
                if (doc.specialId === res.resourceId) {
                    sortedResources.push(res);
                }
            });
        } else {
            resourceIds.push(doc.resourceId);
            if (doc.specialId) {
                resourceIds.push(doc.specialId);
            }
        }
    });

    if (!resources) {
        const uniqueResourceIds = Array.from(new Set(resourceIds));
        [sortedResources, stayPeriodDefs] = await Promise.all([
            DomainObjectUtil.getResourcesByIds(apiContext.mxtsApi, uniqueResourceIds, ops),
            fetchStayPeriodDefsByIds({ stayPeriodDefIds: stayDefIds, env: ops }, apiContext.mxtsApi),
        ]);
    }

    if (accoTypeDynamicFieldCode) {
        const dynamicFieldPromises: Array<Promise<MXTS.DynamicFieldInfo[]>> = await sortedResources
            .filter((resource: MXTS.Resource) => resource.type === "ACCOMMODATIONTYPE")
            .map(async (resource: MXTS.Resource) =>
                apiContext.mxtsApi.dynamicFieldsInfoCustomized(ops, {
                    managerId: resource.dynamicManagerId,
                    code: accoTypeDynamicFieldCode,
                    widgetOptionsId,
                    widgetOptionsDynamicFieldCodesPaths,
                })
            );
        const dynamicFields = await Promise.all(dynamicFieldPromises);
        sortedResources.forEach((resource: MXTS.Resource) => {
            if (resource.type === "ACCOMMODATIONTYPE") {
                const fields = dynamicFields.filter((dynamicField: MXTS.DynamicFieldInfo[]) =>
                    dynamicField && dynamicField[0] ? dynamicField[0].dynamicManagerId === resource.dynamicManagerId : false
                );
                if (fields) {
                    resource.dynamicFields = fields[0];
                }
            }
        });
    }

    if (widgetOptions.useDynamicFieldToShowLocation && widgetOptions.locationDynamicField && widgetOptions.locationDynamicField.length > 0) {
        const dynamicFieldCode = widgetOptions.locationDynamicField[0];
        const dynamicFieldPromises: Array<Promise<MXTS.DynamicFieldInfo[]>> = await sortedResources
            .filter((resource: MXTS.Resource) => resource.type === "ACCOMMODATIONTYPE")
            .map(async (resource: MXTS.Resource) =>
                apiContext.mxtsApi.dynamicFieldsInfoCustomized(ops, {
                    managerId: resource.dynamicManagerId,
                    code: (dynamicFieldCode as any).value,
                    widgetOptionsId,
                    widgetOptionsDynamicFieldCodesPaths,
                })
            );
        const dynamicFields = await Promise.all(dynamicFieldPromises);
        sortedResources.forEach((resource: MXTS.Resource) => {
            if (resource.type === "ACCOMMODATIONTYPE") {
                const fields = dynamicFields.find((dynamicField: MXTS.DynamicFieldInfo[]) =>
                    dynamicField && dynamicField[0] ? dynamicField[0].dynamicManagerId === resource.dynamicManagerId : false
                );
                if (fields) {
                    (resource as any).city = fields[0].value;
                }
            }
        });
    }

    const initAvailability = await parseSearchFacetAvailability(accoKinds.content, stayPeriodDefs!, { filter: availabilityFilter, response }, amenityCategories, resortAmenities, availableResorts);
    const availabilityRequest: AvailabilityRequest = {
        resources: sortedResources,
        availabileResources: { filter: availabilityFilter, response },
        availability: { filter: availabilityFilter, response },
        resorts: availableResorts,
        accoKinds: accoKinds.content,
        stayPeriodDefs: stayPeriodDefs!,
        addresses,
        amenityCategories,
        subjects,
        selectedSubjects,
        amenityIds: selectedAmenityIds,
        holiday: filter.holiday,
    };
    const typeSearchResult = await parseAvailability(availabilityRequest, false);
    return { availabilityRequest, availability: { filter: availabilityFilter, response }, baseFilter, ops, typeSearchResult, SearchFacetAvailability: initAvailability };
}
