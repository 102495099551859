import * as React from "react";

import { AmenityCategory, AvailabilityResult, Resource, ResourceType } from "@maxxton/cms-mxts-api";
import { AvailabilityModel, getTypeSearchData } from "./mxts";
import { WebContent as CmsApiWebcontent, SiteApi, TemplateApi, WebContentApi, WithId, getPageUrl } from "@maxxton/cms-api";
import { LocaleContent, WidgetOptions } from "./TypeSearch";
import { TypeSearchResult, retrieveFilter } from "../";
import { getHideWidgetClass, isClientLoggedIn, isEqual, sortResults } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { ActionType } from "../../../redux/actions";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../../dynamic/dynamicWidget.types";
import { Element } from "react-scroll";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import ProgressBar from "../../../components/ProgressBar";
import { SearchFacetState } from "../searchfacet/searchFacet.types";
import { Sort } from "../searchfacet/searchFacet.enum";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import namespaceList from "../../../i18n/namespaceList";
import { parse } from "query-string";
import { renderNoResultsFoundContent } from "../../dynamic/containerWidget.util";
import { renderPageWidgets } from "../../widget";

const TypeSearch = loadable(() => loadableRetry(() => import("./TypeSearch")), {
    resolveComponent: ({ TypeSearch }) => TypeSearch,
});
const WebContent = loadable(() => loadableRetry(() => import("../../page/web-content/WebContent")), {
    resolveComponent: ({ WebContent }) => WebContent,
});
const SearchFacet = loadable(() => loadableRetry(() => import("../searchfacet/SearchFacet")), {
    resolveComponent: ({ SearchFacet }) => SearchFacet,
});

interface TypeSearchContainerBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    selectedAmenityCategories: AmenityCategory[] | null;
}

interface TypeSearchDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction>;
}

interface TypeSearchContainerProps extends TypeSearchContainerBaseProps, TypeSearchContainerStoreProps, TypeSearchDispatchProps {}

interface TypeSearchContainerStoreProps {
    dynamicFilter: DynamicFilter;
}

interface TypeSearchContainerState {
    isTypesLoading: boolean;
    isFacetsLoading: boolean;
    searchFacetState?: SearchFacetState;
    fromIndex: number;
    toIndex: number;
    totalResult?: number;
    allAccoKindUrl?: string;
    allResortUrl?: string;
    disableWidget: boolean;
    availability?: AvailabilityResult;
    directSearchValue?: string;
    directSearchId?: number;
    totalTypeSearchResult: TypeSearchResult;
}

class TypeSearchContainerBase extends React.Component<TypeSearchContainerProps, TypeSearchContainerState> {
    private availabilityModel: AvailabilityModel;
    private webContent: (CmsApiWebcontent & WithId) | null;
    private template: JSX.Element[] | null;
    private erroWhileFetchingAvailability: boolean;
    private localized: LocaleContent;

    constructor(props: TypeSearchContainerProps) {
        super(props);
        this.state = {
            isTypesLoading: true,
            isFacetsLoading: true,
            searchFacetState: undefined,
            fromIndex: 0,
            toIndex: +props.options.defaultNumberOfTypes! || 5,
            disableWidget: true,
            totalTypeSearchResult: [],
            directSearchId: undefined,
            directSearchValue: "",
        };
        this.searchFacetUpdated = this.searchFacetUpdated.bind(this);
        this.fetchNextItems = this.fetchNextItems.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
    }

    public searchFacetUpdated(searchFacetState: SearchFacetState, selected?: boolean) {
        if (!isEqual({ ...searchFacetState, newState: undefined }, { ...this.state.searchFacetState, newState: undefined })) {
            this.setState({ isTypesLoading: true, fromIndex: 0, toIndex: +this.props.options.defaultNumberOfTypes! || 5, searchFacetState });
            const options = parse(this.props.context.location.search);
            getTypeSearchData(
                this.props.context,
                this.props.options,
                options,
                0,
                +this.props.options.defaultNumberOfTypes! || 5,
                parse(this.props.context.location.search),
                this.props.context.currentLocale.code,
                searchFacetState,
                this.props.options.useResortForSubjects,
                this.props.options.resortId,
                selected,
                this.props.options.dateRangePicker
            ).then((res: AvailabilityModel) => {
                this.processResponse(res);
            }, this.errorHandler);
        } else {
            this.setState({ searchFacetState });
        }
    }

    public fetchNextItems(typeSearchResult: TypeSearchResult) {
        const { fromIndex, toIndex } = this.state;
        const options = retrieveFilter(parse(this.props.context.location.search));
        const defaultFetchCount = +this.props.options.defaultNumberOfTypes! || 5;
        const nextFetchCount = +this.props.options.nextNumberOfTypes! || 5;
        const newFromIndex = fromIndex + (fromIndex === 0 ? defaultFetchCount : nextFetchCount);
        const newToIndex = toIndex + nextFetchCount;
        getTypeSearchData(
            this.props.context,
            this.props.options,
            options,
            newFromIndex,
            newToIndex,
            parse(this.props.context.location.search),
            this.props.context.currentLocale.code,
            this.state.searchFacetState,
            this.props.options.useResortForSubjects,
            this.props.options.resortId,
            undefined,
            this.props.options.dateRangePicker
        ).then(
            (res: AvailabilityModel) => {
                this.availabilityModel = res;
                const sortedTypeSearchResult = sortResults(
                    typeSearchResult.concat(this.availabilityModel.typeSearchResult),
                    this.state.searchFacetState ? this.state.searchFacetState.sortingOption : Sort[Sort.highToLowRating],
                    typeSearchResult.concat(this.availabilityModel.typeSearchResult),
                    true
                );
                this.availabilityModel.typeSearchResult = sortedTypeSearchResult;
                this.setState(() => ({ fromIndex: newFromIndex, toIndex: newToIndex }));
            },
            (err: any) => {
                this.setState({ isTypesLoading: false });
                throw err;
            }
        );
    }

    public setStickyComponent(getStickyId: string) {
        let originalSearchTop = 0;
        const windowObject = window;
        const sticky = document.getElementsByClassName(getStickyId) ? document.getElementsByClassName(getStickyId).item(0) : null;

        const stickyFacet = document.getElementsByClassName("sticky-facet") ? document.getElementsByClassName("sticky-facet").item(0) : null;

        const stickyTitle = document.getElementsByClassName("search-facet-title") ? document.getElementsByClassName("search-facet-title").item(0) : null;

        const facetFilters = document.getElementsByClassName("sort-and-filters") ? document.getElementsByClassName("sort-and-filters").item(0) : null;

        window.addEventListener("scroll", () => {
            if (sticky && stickyFacet) {
                const stickyHeight = sticky.getBoundingClientRect().height;
                const stickyTitleHeight = stickyTitle ? stickyTitle.getBoundingClientRect().height : 0;
                stickyFacet.setAttribute("style", `height: ${stickyHeight}px`);
                originalSearchTop = stickyFacet.getBoundingClientRect().top + window.pageYOffset;
                const elTopSearch = stickyFacet.getBoundingClientRect().top;
                if (windowObject.pageYOffset > elTopSearch || elTopSearch === 0) {
                    sticky.setAttribute("style", "position: fixed; top: 0; z-index: 92 !important");
                    if (stickyTitle) {
                        stickyTitle.setAttribute("style", "display: none");
                    }
                    facetFilters!.setAttribute("style", `padding-top: ${stickyTitleHeight + 20}px`);
                }
                if (windowObject.pageYOffset < originalSearchTop) {
                    sticky.setAttribute("style", "position: relative; !important");
                    if (stickyTitle) {
                        stickyTitle.setAttribute("style", "display: block");
                    }
                    facetFilters!.setAttribute("style", "padding-top: 0");
                }
            }
        });

        window.addEventListener("resize", () => {
            if (sticky && stickyFacet) {
                const stickyHeight = sticky.getBoundingClientRect().height;
                const stickyTitleHeight = stickyTitle ? stickyTitle.getBoundingClientRect().height : 0;
                stickyFacet.setAttribute("style", `height: ${stickyHeight}px`);
                originalSearchTop = stickyFacet.getBoundingClientRect().top + window.pageYOffset;
                stickyFacet.setAttribute("style", `height: ${stickyHeight}px`);
                const elTopSearch = stickyFacet.getBoundingClientRect().top;
                if (windowObject.pageYOffset > elTopSearch || elTopSearch === 0) {
                    sticky.setAttribute("style", "position: fixed; top: 0; z-index: 92 !important");
                    if (stickyTitle) {
                        stickyTitle.setAttribute("style", "display: none");
                    }
                    facetFilters!.setAttribute("style", `padding-top: ${stickyTitleHeight + 20}px`);
                }
                if (windowObject.pageYOffset < originalSearchTop) {
                    sticky.setAttribute("style", "position: relative; !important");
                    if (stickyTitle) {
                        stickyTitle.setAttribute("style", "display: block");
                    }
                    facetFilters!.setAttribute("style", "padding-top: 0");
                }
            }
        });
    }

    public componentDidMount() {
        const { fromIndex, toIndex } = this.state;
        const { options, context, dispatchAction } = this.props;
        this.localized = options.localizedContent.find((lc) => lc.locale === context.currentLocale.locale)!;
        if (localStorage) {
            localStorage.removeItem("diffWidgetOptions");
            localStorage.removeItem("sameWidgetOptions");
        }
        if (this.localized && this.localized.showAccoKindCriteriaText) {
            if (this.localized.useAccoKindFriendlyUrl) {
                this.setState({ allAccoKindUrl: this.localized.allAccoKindUrl });
            } else if (this.localized.allAccoKindPageId && this.localized.allAccoKindSiteId) {
                getPageUrl({ siteId: this.localized.allAccoKindSiteId, pageId: this.localized.allAccoKindPageId, siteApi: SiteApi }).then((url: string) => {
                    this.setState({ allAccoKindUrl: url });
                });
            }
        }

        if (this.localized && this.localized.showResortCriteriaText) {
            if (this.localized.useResortFriendlyUrl) {
                this.setState({ allResortUrl: this.localized.allResortUrl });
            } else if (this.localized.allResortPageId && this.localized.allResortSiteId) {
                getPageUrl({ siteId: this.localized.allResortSiteId, pageId: this.localized.allResortPageId, siteApi: SiteApi }).then((url: string) => {
                    this.setState({ allResortUrl: url });
                });
            }
        }

        const urlOptions = retrieveFilter(parse(this.props.context.location.search));
        getTypeSearchData(
            this.props.context,
            options,
            urlOptions,
            fromIndex,
            toIndex,
            parse(this.props.context.location.search),
            this.props.context.currentLocale.code,
            this.state.searchFacetState,
            this.props.options.useResortForSubjects,
            this.props.options.resortId,
            undefined,
            this.props.options.dateRangePicker
        ).then((res: AvailabilityModel) => {
            this.availabilityModel = res;
            this.setState(
                {
                    availability: res.availability,
                },
                () => {
                    this.fetchAllTypeSearchResults(res);
                    this.processResponse(res);
                }
            );
        }, this.errorHandler);
        this.setState({ disableWidget: !isClientLoggedIn() });

        if (!options.dateRangePicker) {
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.shouldFetchStayPeriods,
                payload: {
                    shouldFetchStayPeriods: true,
                },
            };
            dispatchAction(action);
        }
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", () => 0);
        window.removeEventListener("scroll", () => 0);
    }

    public render(): JSX.Element | null {
        const { options, context, dynamicFilter } = this.props;
        const { allAccoKindUrl, allResortUrl, isFacetsLoading, isTypesLoading } = this.state;
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        if (isTypesLoading && isFacetsLoading) {
            return <ProgressBar />;
        }

        if (!this.availabilityModel) {
            return (
                <div className={`${hideWidget}`}>{!isTypesLoading && renderNoResultsFoundContent({ noResultsFoundWebContent: this.webContent, noResultsFoundTemplate: this.template, context })}</div>
            );
        }

        const availability = this.availabilityModel.availabilityRequest;

        const typesFound = availability.availability.response.resources!.length > 0;

        return (
            <div className={`type-search-facet search-list-container ${hideWidget}`}>
                <Element name="result" />
                <div className="overlay" />
                <SearchFacet
                    hideAccoKinds={options.hideAllAccoKindsFilter}
                    className={`${options.setStickyId ? options.setStickyId : ""}`}
                    context={context}
                    apiOptions={this.availabilityModel.ops}
                    title={this.localized ? this.localized.facetTitle : undefined}
                    dateRangePicker={options.dateRangePicker}
                    unitBookUri={this.localized.unitBookUri || options.unitBookUri}
                    accokinds={availability.accoKinds}
                    resorts={availability.resorts}
                    subjects={availability.subjects}
                    amenityCategories={availability.amenityCategories}
                    baseFilter={this.availabilityModel.baseFilter}
                    initialAvailability={this.availabilityModel.SearchFacetAvailability}
                    numberOfResult={availability.availability.response.resources!.length}
                    stateChanged={this.searchFacetUpdated}
                    typeSearchWidgetOptions={options}
                    selectedSubjects={availability.selectedSubjects}
                    amenityIds={availability.amenityIds}
                    multiAccoKindSelector={options.multiAccoKindSelector}
                    multiResortSelector={options.multiResortSelector}
                    accoKindCriteriaText={this.localized ? this.localized.accoKindCriteriaText : undefined}
                    allAccoKindUrl={allAccoKindUrl}
                    resortCriteriaText={this.localized ? this.localized.resortCriteriaText : undefined}
                    allResortUrl={allResortUrl}
                    searchButton={options.searchButton}
                    showDateStayPopup={false}
                    showDirectSearchFilter={options.showDirectSearchFilter}
                    hideUnitDirectSearch={options.hideUnitDirectSearch}
                    holiday={availability.holiday}
                    specials={this.getSpecials(availability.resources)}
                    filterDirectSeachByInput={this.filterDirectSeachByInput}
                    filterDirectSearchById={this.filterDirectSearchById}
                    redirectBookingsEngine={false}
                    totalTypeSearchResult={this.state.totalTypeSearchResult}
                    selectedAmenityCategories={this.props.selectedAmenityCategories}
                    isFetchingResults={isTypesLoading || isFacetsLoading}
                    showStartEndDateLabel={options.showStartEndDateLabel}
                    dateFormat={options.dateFormat}
                />
                {this.webContent && (this.erroWhileFetchingAvailability || !typesFound) && !isTypesLoading && <WebContent id={this.webContent._id} content={this.webContent} context={context} />}
                {this.template && (this.erroWhileFetchingAvailability || !typesFound) && !isTypesLoading && <div className={"template"}>{this.template}</div>}
                {typesFound && !isTypesLoading && !this.erroWhileFetchingAvailability && (
                    <TypeSearch
                        typeSearchResult={this.availabilityModel.typeSearchResult}
                        totalTypeSearchResult={this.state.totalTypeSearchResult}
                        baseFilter={this.availabilityModel.baseFilter}
                        apiOptions={this.availabilityModel.ops}
                        options={options}
                        amenities={this.state.searchFacetState ? this.state.searchFacetState.amenityIds : []}
                        fetchNextItems={this.fetchNextItems}
                        showMore={this.state.totalResult! > this.state.toIndex && !(this.state.directSearchId || this.state.directSearchValue !== "")}
                        stayPeriodDefs={availability.stayPeriodDefs}
                        context={context}
                        availability={this.state.availability}
                        directSearchValue={this.state.directSearchValue}
                        directSearchId={this.state.directSearchId}
                        dynamicFilter={dynamicFilter}
                    />
                )}
                {isTypesLoading && <ProgressBar />}
            </div>
        );
    }

    private filterDirectSeachByInput = (inputValue: string) => {
        this.setState({ directSearchValue: inputValue });
    };

    private filterDirectSearchById = (inputId: number) => {
        this.setState({ directSearchId: inputId });
    };

    private async errorHandler(error: any) {
        const { currentLocale, site } = this.props.context;
        this.erroWhileFetchingAvailability = true;
        this.webContent = await this.getNoDataFoundContent();
        this.template = await this.getNoDataFoundTemplate();
        this.setState({ isTypesLoading: false });
        this.props.context.alerts.push({
            color: "danger",
            message: getI18nLocaleString(namespaceList.widgetSearchfacet, "noDataFound", currentLocale, site),
        });
        throw error;
    }

    private getSpecials(resources: Resource[]): Resource[] {
        return resources.filter((resource) => resource.type === ResourceType.SPECIAL);
    }

    private async processResponse(res: AvailabilityModel) {
        const { options } = this.props;
        const totalResult = res.availabilityRequest.availability.response.resources!.length;
        this.erroWhileFetchingAvailability = false;
        this.availabilityModel = res;
        if (totalResult <= 0) {
            this.webContent = await this.getNoDataFoundContent();
            this.template = await this.getNoDataFoundTemplate();
        }
        this.setState(
            {
                isTypesLoading: false,
                isFacetsLoading: false,
                totalResult,
            },
            () => {
                const getStickyId = options.setStickyId ? options.setStickyId : "";
                if (getStickyId && getStickyId !== "") {
                    this.setStickyComponent(getStickyId);
                }
            }
        );
    }

    private async fetchAllTypeSearchResults(res: AvailabilityModel) {
        const { fromIndex } = this.state;
        const { options } = this.props;
        const toIndex = res.availabilityRequest.availability.response.resources!.length;
        const urlOptions = retrieveFilter(parse(this.props.context.location.search));
        getTypeSearchData(
            this.props.context,
            options,
            urlOptions,
            fromIndex,
            toIndex,
            parse(this.props.context.location.search),
            this.props.context.currentLocale.code,
            this.state.searchFacetState,
            this.props.options.useResortForSubjects,
            this.props.options.resortId,
            undefined,
            this.props.options.dateRangePicker
        ).then((result: AvailabilityModel) => {
            this.setState({ totalTypeSearchResult: result.typeSearchResult });
        }, this.errorHandler);
    }

    private async getNoDataFoundContent(): Promise<(CmsApiWebcontent & WithId) | null> {
        const { webContentId } = this.props.options;
        if (webContentId) {
            return WebContentApi.findById({ id: webContentId });
        }
        return null;
    }

    private async getNoDataFoundTemplate(): Promise<JSX.Element[] | null> {
        const { templateId } = this.props.options;
        if (templateId) {
            const template = await TemplateApi.findById({ id: templateId });
            if (template) {
                return await renderPageWidgets(template.root, this.props.context);
            }
        }
        return null;
    }
}

function mapStateToProps(state: State): TypeSearchContainerStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction>): TypeSearchDispatchProps {
    return { dispatchAction: dispatch };
}

const TypeSearchContainerWidget = connect<TypeSearchContainerStoreProps, TypeSearchDispatchProps>(mapStateToProps, mapDispatchToProps)(TypeSearchContainerBase);

export const TypeSearchContainer = wrapProps<TypeSearchContainerBaseProps>(TypeSearchContainerWidget);
